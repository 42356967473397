import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import ExternalLink from '../../../components/external-link'
import ActiveLink from '../../../components/active-link'
import Hero from '../../../components/hero'
import chart1 from '../../../images/Continuous_LH_Chart.png'
import chart2 from '../../../images/Sustained_LH_Chart.png'

const metaTags = {
  description: 'Learn about the SUPPRELIN® LA SHARES program including who may qualify for savings.',
  keywords: 'Homepage',
  title: 'Savings | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true}>
    <Row>
      <Col xs={12}>
        <h1>The SUPPRELIN<sup>&reg;</sup> LA SHARES Copay Assistance Program</h1>
        <h2>Eligible patients may save up to $4,000 after a $10 copay</h2>
        <p>The majority of patients enrolled in the SHARES Copay Assistance Program pay $10 a year for SUPPRELIN<sup>&reg;</sup> LA. For those who exceed the $4,000 amount, they may be eligible for additional assistance.<br/><br/>Restrictions may apply. See terms and conditions.*</p>
        <p className='footnote asterik'>Offer good only in the USA and void where prohibited or otherwise restricted by law. Offer not valid for prescriptions that may be reimbursed under a federal or state healthcare program, including Medicare, Medicaid, or any other federal or state healthcare programs, including any state medical pharmaceutical assistance programs. Patients over the age of 18 are not eligible. Endo Pharmaceuticals Inc. has the right to rescind, revoke, or amend this program without notice. This offer is not transferable. </p>
      </Col>
    </Row>

    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix("/pdfs/SP-05685_SHARES_Leave_Behind_R1_Digital.pdf")}
          LinkCaption="Download the SHARES Copay Information Sheet for your parents and caregivers - shares copay program"
        >
          Download the SHARES Copay Information Sheet for your parents and caregivers
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/support-center-and-savings"
          LinkCaption="Learn how the Support Center assists with insurance coverage and access - shares copay program"
        >
          Learn how the Support Center assists with insurance coverage and access
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
